import { CREATORS_PROD_DOMAIN } from "@/configs/creators";

/**
 * Convert a given brand URL string to a relative path by removing
 * the protocol and domain, as well as the "/creators" prefix if present.
 *
 * If conversion fails, return the original URL.
 *
 * @param url the URL string to convert
 * @returns a relative path version of the given URL string
 */
export function brandUrlToRelativePath(url: string): string {
  try {
    const parsedUrl = new URL(url);
    // Remove "/creators" from the path, if present.
    const pathNameWithoutCreators =
      parsedUrl.pathname.match(/(\/creators)?(\/.+)/)?.[2];
    // Enforce trailing slash for brand URLs.
    const pathPart = pathNameWithoutCreators!.endsWith("/")
      ? pathNameWithoutCreators
      : pathNameWithoutCreators + "/";
    return pathPart + parsedUrl.search;
  } catch {
    return url;
  }
}

/**
 * Takes in a brand URL, and replaces the old creators domain with the new one.
 *
 * This function is a tempory fix while NCP returns brand urls in the form of
 * `www.yahoo.com/creators/<brand>`. If passed into this function, it will be
 * converted to `creators.yahoo.com/<brand>/`.
 *
 * @returns The fully qualified brand URL on creators.yahoo.com.
 */
export function fixBrandUrlDomain(url: string): string {
  const relUrl = brandUrlToRelativePath(url);

  // return early for invalid URLs
  if (relUrl === url) {
    return url;
  }

  return `https://${CREATORS_PROD_DOMAIN}${relUrl}`;
}
