"use client";
import classNames from "classnames";
import { usePathname } from "next/navigation";
import { useIntl } from "react-intl";
import { SiteFooter } from "@/components/shared/SiteFooter";

type Styles = {
  container?: string;
  disclaimer?: string;
};

export const FooterContent = ({ styles }: { styles?: Styles }) => {
  const intl = useIntl();
  return (
    <div
      className={classNames(
        "flex flex-col items-center justify-center text-center",
        styles?.container,
      )}
    >
      <SiteFooter className="text-center xl:max-w-screen-lg" />
      <small
        className={classNames(
          "mt-1 block text-xs text-dolphin md:text-base",
          styles?.disclaimer,
        )}
      >
        {intl.formatMessage({ id: "creators.DISCLAIMER" })}
      </small>
    </div>
  );
};

export const Footer = () => {
  const pathname = usePathname();
  return (
    <footer
      className={classNames("bg-white px-2 pb-4", {
        "lg:hidden": pathname === "/creators",
      })}
    >
      <FooterContent />
    </footer>
  );
};
