import { Component, type ErrorInfo } from "react";
import { beaconError } from "@/lib/beacon";

const NOOP = () => {};

type Props = {
  // normal UI when no error is thrown
  children: React.ReactNode;
  // optional fallback ui when error
  // is thrown. If error is thrown and
  // no fallback is passed in, we show
  // nothing in its place.
  fallback?: React.ReactNode;
  // unique identifier for this boundary
  // helps when debugging from logs
  id: string;
};

type State = {
  error: Error | null;
  hasError: boolean;
  id: string;
};

class ErrorBoundary extends Component<Props, State> {
  constructor(props: Props) {
    super(props);
    this.state = { error: null, hasError: false, id: props.id };
  }

  static getDerivedStateFromError(error: Error) {
    return { error, hasError: true };
  }

  componentDidCatch(error: Error, errorInfo: ErrorInfo) {
    beaconError(this.state.id, error, errorInfo).catch(NOOP);
    console.error("Error Boundary caught an error:", error, errorInfo);
  }

  render() {
    if (this.state.hasError) {
      // Fallback UI
      return this.props.fallback ? this.props.fallback : null;
    }

    // Render children components normally
    return this.props.children;
  }
}

export default ErrorBoundary;
