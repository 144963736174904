"use client";

import { throttle } from "@yahoo-news/util";
import classNames from "classnames";
import React, { useEffect, useId, type FC } from "react";
import { useIntl } from "react-intl";
import ErrorBoundary from "@/components/common/ErrorBoundary";
import { useSlideshow } from "@/hooks/useSlideshow";
import { CarouselNavControls } from "./CarouselNavControls";
import {
  CarouselNavIndicators,
  type CarouselNavIndicatorStyles,
} from "./CarouselNavIndicators";

const carouselBaseStyles = classNames(
  "duration-500",
  "motion-reduce:duration-0",
  "ease-in-out",
  "no-scrollbar",
  "relative",
  "snap-x",
  "snap-mandatory",
  "overflow-x-scroll",
);

const slideBaseStyles = classNames("snap-start");

export type SlideDirection = "next" | "prev";

// TODO: Fix navStyle & navType types since its in child -> how best to share them
// Create Carousel.types ?
interface Props {
  carouselLabel?: string;
  carouselStyles?: string;
  disableNavIndicator?: boolean;
  displaySlideCount?: boolean;
  itemsPerSlide?: number;
  navIndicatorStyles?: CarouselNavIndicatorStyles;
  navStyles?: any;
  navType?: any;
  slides: React.ReactElement[];
  slideStyles?: string;
  title?: string;
  titleStyles?: string;
  onScrollChange?: (
    clientWidth: number,
    scrollWidth: number,
    scrollLeft: number,
    scrollTop: number,
  ) => void;
}

interface carouselAriattributes {
  "aria-roledescription": string;
  "aria-label"?: string;
}

export const Carousel: FC<Props> = ({
  carouselLabel,
  carouselStyles,
  disableNavIndicator,
  displaySlideCount,
  itemsPerSlide = 1,
  navIndicatorStyles,
  navStyles,
  navType,
  slides,
  slideStyles,
  title,
  titleStyles,
  onScrollChange,
}) => {
  const { formatMessage } = useIntl();

  const slideCount = Math.ceil(slides.length / itemsPerSlide);

  const {
    carouselRef,
    scrollOnClick,
    slideIndex,
    slideRefs,
    switchSlideItem,
    slideKeyDown,
  } = useSlideshow({ itemsPerSlide, slideCount });

  // If a scroll change listener was passed, call it on scroll events.
  useEffect(() => {
    const carousel = carouselRef.current;
    if (!carousel || !onScrollChange) return;

    const onScroll = throttle(() => {
      onScrollChange(
        carousel.clientWidth,
        carousel.scrollWidth,
        carousel.scrollLeft,
        carousel.scrollTop,
      );
    }, 1000 / 60); // limit to 60 fps

    carousel.addEventListener("scroll", onScroll);

    return () => {
      carousel.removeEventListener("scroll", onScroll);
    };
  }, [carouselRef, onScrollChange, slideIndex]);

  let carouselAttribs: carouselAriattributes = {
    "aria-roledescription": "carousel",
  };
  if (carouselLabel) {
    carouselAttribs["aria-label"] = carouselLabel;
  }

  // TODO FIX  Prop "data-testid" is forbidden on DOM Nodes  react/forbid-dom-props
  // TODO do we need section container extra styles?
  return (
    <ErrorBoundary id={useId()}>
      <section // eslint-disable-line jsx-a11y/no-static-element-interactions
        className={classNames("relative z-[1]")}
        {...carouselAttribs}
        onKeyDown={slideKeyDown}
      >
        {title && (
          <header>
            <h2
              className={classNames(
                "mb-6 inline-block font-centra text-3xl text-inkwell",
                titleStyles,
              )}
            >
              {title}
            </h2>
          </header>
        )}
        <CarouselNavControls
          canNavigateNext={slideIndex + itemsPerSlide < slides.length}
          canNavigatePrev={slideIndex > 0}
          displaySlideCount={displaySlideCount}
          slideIndex={Math.ceil(slideIndex / itemsPerSlide) + 1}
          switchSlideItem={switchSlideItem}
          navStyles={navStyles}
          navType={navType}
          totalSlides={slideCount}
        />
        <div
          className={classNames(carouselBaseStyles, carouselStyles)}
          ref={carouselRef}
        >
          {slides.map((item, index) => (
            <div
              aria-roledescription="slide"
              role="group"
              aria-label={formatMessage(
                { id: "article.CAROUSEL_SLIDE_ARIA_LABEL" },
                { index: index + 1, total: slides.length },
              )} // eslint-disable-next-line react/forbid-dom-props
              data-testid="carousel"
              data-inview={
                slideIndex * itemsPerSlide <= index &&
                index < (slideIndex + 1) * itemsPerSlide
              }
              key={`carousel-${index}`}
              className={classNames(slideBaseStyles, slideStyles)}
              ref={(el) => {
                slideRefs.current[index] = el!;
              }}
            >
              {item}
            </div>
          ))}
        </div>
        {!disableNavIndicator && (
          <CarouselNavIndicators
            styles={navIndicatorStyles}
            slideIndex={slideIndex}
            totalSlidesXs={slides.length}
            onIndexClick={(index: number) => scrollOnClick(index, true)}
          />
        )}
      </section>
    </ErrorBoundary>
  );
};
