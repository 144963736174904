"use client";
import { useIntl } from "react-intl";

export const PageTitle = () => {
  const intl = useIntl();
  return (
    <div className="flex size-full border-t">
      <div className="mx-auto flex w-full max-w-uh-custom flex-col justify-center gap-2 px-4 py-8 md:px-6 lg:px-8">
        <h1 className="-mt-1 w-full align-middle font-centra text-[28px] font-extrabold leading-none tracking-tight text-inkwell md:text-[32px] lg:text-[36px]">
          {intl.formatMessage({ id: "creators.PAGE_TITLE" })}
        </h1>
        <p className="ml-[2px] text-lg font-normal leading-5 text-battleship md:ml-[2px]">
          {intl.formatMessage({ id: "creators.PAGE_SUBTITLE" })}{" "}
        </p>
      </div>
    </div>
  );
};
