import { I13nButton } from "@yahoo-creators/i13n";
import classNames from "classnames";
import { useCallback } from "react";
import { useIntl } from "react-intl";
import PrevArrow from "@/svgs/prevArrow.svg";
import { type SlideDirection } from "./Carousel";

type Props = {
  canNavigateNext: boolean;
  canNavigatePrev: boolean;
  displaySlideCount?: boolean;
  slideIndex?: number;
  switchSlideItem: (dir: SlideDirection) => void;
  navStyles?: NavStyles;
  navType?: keyof CarouselStyles;
  totalSlides?: number;
};

type ButtonProps = {
  canNavigate: boolean;
  direction: SlideDirection;
  label: string;
  switchSlideItem: (dir: SlideDirection) => void;
  buttonStyles: NavStyles;
};

export interface NavStyles {
  button: string;
  disabled: string;
  enabled: string;
  container: string;
  next: string;
  prev: string;
  slideCount: string;
}

type CarouselStyles = {
  pillStyles: NavStyles;
  roundStyles: NavStyles;
};

// 2 Default styles but users can pass navStyles in to create own styles
export const carouselStyles: CarouselStyles = {
  pillStyles: {
    button: "flex items-center p-2 justify-center rounded-l-[99px]",
    container: "absolute right-0 top-0 z-[1] hidden md:flex lg:flex",
    disabled: "bg-dirty-seagull text-pebble cursor-not-allowed",
    enabled: "bg-grey-hair text-brand-purple hover:bg-pebble",
    next: "rotate-180",
    prev: "",
    slideCount: "",
  },
  roundStyles: {
    button:
      "flex items-center p-2 justify-center rounded-full border border-[#ebe5ff]",
    container: "absolute right-0 top-0 z-[1] flex gap-2",
    disabled: "bg-dirty-seagull text-pebble cursor-not-allowed",
    enabled: "bg-grey-hair text-brand-purple hover:bg-pebble",
    next: "rotate-180",
    prev: "",
    slideCount: "",
  },
};

function NavButton({
  buttonStyles,
  direction,
  label,
  canNavigate,
  switchSlideItem,
}: ButtonProps) {
  const handleClick = useCallback(() => {
    if (canNavigate) {
      switchSlideItem(direction);
    }
  }, [canNavigate, direction, switchSlideItem]);

  return (
    <I13nButton
      aria-label={label}
      className={classNames(
        buttonStyles.button,
        canNavigate ? buttonStyles.enabled : buttonStyles.disabled,
        direction === "prev" ? buttonStyles.prev : buttonStyles.next,
      )}
      aria-disabled={!canNavigate}
      onClick={handleClick}
      dataYlk={{
        cpos: 1,
        elm: "arrow",
        itc: 1,
        mpos: 1,
        pos: 1,
        pt: "",
        slk: direction,
      }}
    >
      <PrevArrow className="fill-current" height={12} width={12} />
    </I13nButton>
  );
}
// TODO could allow merging of navType/passing own navType
export const CarouselNavControls = ({
  canNavigateNext,
  canNavigatePrev,
  displaySlideCount,
  slideIndex,
  switchSlideItem,
  navStyles,
  navType = "pillStyles",
  totalSlides,
}: Props) => {
  const { formatMessage } = useIntl();
  // This is overkill to get around TS lol
  let navStyle = carouselStyles[navType];
  if (!navStyles && navType !== "pillStyles" && navType in carouselStyles) {
    navStyle = carouselStyles[navType];
  } else if (navStyles) {
    navStyle = navStyles;
  }

  return (
    <div
      aria-label={formatMessage({ id: "article.SLIDE_CONTROLS" })}
      role="group"
      className={navStyle.container}
    >
      {displaySlideCount && (
        <div className={navStyle.slideCount || "mr-1 self-center text-sm"}>
          {formatMessage(
            { id: "article.CAROUSEL_SLIDE_COUNT" },
            { index: slideIndex, total: totalSlides },
          )}
        </div>
      )}
      <NavButton
        buttonStyles={navStyle}
        canNavigate={canNavigatePrev}
        direction="prev"
        label={formatMessage({ id: "article.PREV_SLIDE" })}
        switchSlideItem={switchSlideItem}
      />
      <NavButton
        buttonStyles={navStyle}
        canNavigate={canNavigateNext}
        direction="next"
        label={formatMessage({ id: "article.NEXT_SLIDE" })}
        switchSlideItem={switchSlideItem}
      />
    </div>
  );
};
