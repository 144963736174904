"use client";

import {
  type AdLoadingMode,
  buildResponsiveAdProps,
  ResponsiveAd,
} from "@yahoo-creators/monetization";
import { SCREEN_WIDTH } from "@yahoo-news/util";
import classNames from "classnames";
import { memo, type FC } from "react";
import { useRequestContext } from "@/lib/request/client";

const configs = buildResponsiveAdProps([
  {
    device: "dt",
    screenWidth: { from: SCREEN_WIDTH.sm, to: SCREEN_WIDTH.md },
    sizes: [{ height: 250, width: 300 }],
  },
  {
    device: "dt",
    screenWidth: { from: SCREEN_WIDTH.lg },
    sizes: [
      { height: 250, width: 300 },
      { height: 600, width: 300 },
    ],
  },
]);

interface Props {
  adCount: number;
  loading?: AdLoadingMode;
  location: string;
  locationNumber: number;
}
// this component is memoized to prevent unnecessary rerenders of the underlying ad
export const StickyRightRailAd: FC<Props> = memo(
  ({ adCount, location, loading, locationNumber }) => {
    const { site } = useRequestContext();

    return (
      <ResponsiveAd
        className={classNames(
          "sticky top-[100px] flex flex-none",
          locationNumber + 1 == adCount && "mb-32",
        )}
        configs={configs}
        loading={loading}
        location={location}
        locationNumber={locationNumber}
        placement="main"
        pageRegion="index"
        resizable
        site={site}
      />
    );
  },
);
