import { debounce } from "@yahoo-news/util";
import {
  useRef,
  useState,
  useEffect,
  type MutableRefObject,
  useCallback,
  type RefObject,
} from "react";

export const SCROLL_DEBOUNCE = 100;

export type SetActiveIndexArgs = {
  existingIndexes: Set<number>;
  slideCount: number;
  slideIndex: number;
};

type UseSlideshowArgs = {
  direction?: Direction;
  initialSlideIndex?: number;
  itemsPerSlide?: number;
  setActiveIndexesCallback?: (args: SetActiveIndexArgs) => Set<number>;
  slideCount: number;
  useAutoAdvance?: boolean;
};
type Direction = "horizontal" | "vertical";

export const useSlideshow = ({
  direction = "horizontal",
  initialSlideIndex = 0,
  itemsPerSlide = 1,
  setActiveIndexesCallback,
  slideCount,
  useAutoAdvance = false,
}: UseSlideshowArgs): {
  activeIndexes: Set<number>;
  carouselRef: RefObject<HTMLDivElement>;
  scrollOnClick: (index: number, animate: boolean) => void;
  slideIndex: number;
  slideKeyDown: (event: React.KeyboardEvent<HTMLDivElement>) => void;
  slideRefs: MutableRefObject<HTMLDivElement[]>;
  switchSlideItem: (dir: string) => void;
} => {
  const [activeIndexes, setActiveIndexes] = useState<Set<number>>(new Set([0]));
  const [slideIndex, setSlideIndex] = useState(initialSlideIndex);
  const scrollingFromClickRef = useRef(false);
  const timerRef = useRef<NodeJS.Timeout | null>(null);
  const isHoveredRef = useRef(false);
  const isPageVisibleRef = useRef(true);
  const isCarouselVisibleRef = useRef(true);

  const carouselRef = useRef<HTMLDivElement>(null);
  const slideRefs = useRef<HTMLDivElement[]>([]);

  const calculateScrollTo = useCallback(
    (newSlideIndex: number) => {
      const carousel = carouselRef.current;
      if (!carousel) return;

      return direction === "vertical"
        ? { top: (newSlideIndex * carousel.clientHeight) / itemsPerSlide }
        : { left: (newSlideIndex * carousel.clientWidth) / itemsPerSlide };
    },
    [carouselRef, direction, itemsPerSlide],
  );

  const calculateSlideIndex = useCallback(() => {
    const carousel = carouselRef.current;
    if (!carousel) return;

    // Divide to get the slide index
    const index =
      direction === "vertical"
        ? carousel.scrollTop / (carousel.clientHeight / itemsPerSlide)
        : carousel.scrollLeft / (carousel.clientWidth / itemsPerSlide);

    // Modulo to get the remainder in pixels
    const remainder =
      direction === "vertical"
        ? carousel.scrollTop % (carousel.clientHeight / itemsPerSlide)
        : carousel.scrollLeft % (carousel.clientWidth / itemsPerSlide);

    if (remainder === 0) {
      return index;
    } else if (remainder < index * itemsPerSlide) {
      // If the remainder is less than the slide index (very small), it is due to the
      // accumulation of fractional pixels and thus the slide index should be rounded down
      return Math.floor(index);
    } else {
      // Otherwise, the remainder is too great which indicates there is a partial slide and
      // thus the slide index should be rounded up
      return Math.ceil(index);
    }
  }, [carouselRef, direction, itemsPerSlide]);

  // Default behavior for active indexes
  // When slide index changes, make sure the adjacent slides from current are visible
  // By default a node is hidden until its adjacent to the current slide
  // On render it shows the current slide and its prev/next slides
  const defaultSetActiveIndexes = useCallback(
    ({
      existingIndexes,
      slideCount,
      slideIndex,
    }: SetActiveIndexArgs): Set<number> => {
      const nextIndex = (slideIndex + 1) % slideCount;
      const prevIndex = (slideIndex - 1 + slideCount) % slideCount;
      if (existingIndexes.has(nextIndex) && existingIndexes.has(prevIndex)) {
        return existingIndexes;
      } else {
        const newActiveIndexes = new Set(existingIndexes);
        newActiveIndexes.add(nextIndex);
        newActiveIndexes.add(prevIndex);
        return newActiveIndexes;
      }
    },
    [],
  );

  useEffect(() => {
    const impl = setActiveIndexesCallback || defaultSetActiveIndexes;
    setActiveIndexes((existingIndexes: Set<number>) =>
      impl({ existingIndexes, slideCount, slideIndex }),
    );
  }, [
    defaultSetActiveIndexes,
    setActiveIndexesCallback,
    slideCount,
    slideIndex,
  ]);

  useEffect(() => {
    // Add and clean up scroll listener for button navigation
    const carousel = carouselRef.current;
    if (!carousel) return;

    let scrollTimeout: NodeJS.Timeout | null = null;

    const onScroll = debounce(() => {
      if (scrollTimeout) {
        clearTimeout(scrollTimeout);
      }

      scrollTimeout = setTimeout(() => {
        scrollingFromClickRef.current = false;

        const newSlideIndex = calculateSlideIndex();
        if (newSlideIndex !== undefined && newSlideIndex !== slideIndex) {
          setSlideIndex(newSlideIndex);
        }
      }, 100);
    }, SCROLL_DEBOUNCE);

    carousel.addEventListener("scroll", onScroll);

    return () => {
      if (scrollTimeout) {
        clearTimeout(scrollTimeout);
      }
      carousel.removeEventListener("scroll", onScroll);
    };
  }, [calculateSlideIndex, direction, slideIndex]);

  const scrollOnClick = useCallback(
    (newSlideIndex: number, animate: boolean) => {
      const carousel = carouselRef.current;
      if (!carousel) return;

      scrollingFromClickRef.current = true;

      const destination = calculateScrollTo(newSlideIndex);

      carousel.scrollTo({
        behavior: animate ? "smooth" : "auto",
        ...destination,
      });
    },
    [calculateScrollTo],
  );

  // Handle auto advance slide change logic
  const resetTimer = useCallback(() => {
    if (timerRef.current) {
      clearInterval(timerRef.current);
    }

    timerRef.current = setInterval(() => {
      if (!isHoveredRef.current) {
        const nextIndex = (slideIndex + 1) % slideCount;
        const animate = !(slideIndex === slideCount - 1);
        scrollOnClick(nextIndex, animate);
      }
    }, 6500); // 6.5 seconds
  }, [slideCount, scrollOnClick, slideIndex]);

  useEffect(() => {
    if (useAutoAdvance) {
      resetTimer();
    }

    return () => {
      if (timerRef.current) {
        clearInterval(timerRef.current);
      }
    };
  }, [slideIndex, resetTimer, useAutoAdvance]);

  // Pause / Resume on carousel visibility change (if user scrolls away)
  useEffect(() => {
    const carousel = carouselRef.current;
    if (!carousel) return;

    const observer = new IntersectionObserver(
      ([entry]) => {
        isCarouselVisibleRef.current = entry.isIntersecting;

        if (entry.isIntersecting && useAutoAdvance) {
          resetTimer();
        } else if (!entry.isIntersecting && timerRef.current) {
          clearInterval(timerRef.current);
        }
      },
      { threshold: 0.5 }, // Trigger when at least 50% of the carousel is visible
    );

    observer.observe(carousel);

    return () => {
      observer.disconnect();
    };
  }, [resetTimer, useAutoAdvance]);

  // Pause / Resume timer on page visibility (if user leaves tab)
  useEffect(() => {
    const handleVisibilityChange = () => {
      isPageVisibleRef.current = !document.hidden;

      if (isPageVisibleRef.current && useAutoAdvance) {
        resetTimer();
      } else if (!isPageVisibleRef.current && timerRef.current) {
        clearInterval(timerRef.current);
      }
    };

    document.addEventListener("visibilitychange", handleVisibilityChange);

    return () => {
      document.removeEventListener("visibilitychange", handleVisibilityChange);
    };
  }, [resetTimer, useAutoAdvance]);

  // Pause timer on hover and resume on mouse leave
  useEffect(() => {
    const currentSlide = slideRefs.current[slideIndex];
    if (currentSlide && useAutoAdvance) {
      const handleMouseEnter = () => {
        isHoveredRef.current = true;
        if (timerRef.current) {
          clearInterval(timerRef.current);
        }
      };

      const handleMouseLeave = () => {
        isHoveredRef.current = false;
        resetTimer();
      };

      currentSlide.addEventListener("mouseenter", handleMouseEnter);
      currentSlide.addEventListener("mouseleave", handleMouseLeave);

      return () => {
        currentSlide.removeEventListener("mouseenter", handleMouseEnter);
        currentSlide.removeEventListener("mouseleave", handleMouseLeave);
      };
    }
  }, [slideIndex, resetTimer, useAutoAdvance]);

  const switchSlideItem = useCallback(
    (dir: string) => {
      const maxItems = slideCount * itemsPerSlide;
      const newSlideIndex =
        dir === "next"
          ? (slideIndex + itemsPerSlide) % maxItems
          : (slideIndex - itemsPerSlide) % maxItems;
      // Check for wrap-around condition
      const animate =
        !(slideIndex === 0 && dir === "prev") &&
        !(slideIndex >= maxItems - itemsPerSlide && dir === "next");
      scrollOnClick(newSlideIndex, animate);
    },
    [itemsPerSlide, scrollOnClick, slideCount, slideIndex],
  );

  const slideKeyDown = useCallback(
    (event: React.KeyboardEvent<HTMLDivElement>) => {
      switch (event.code) {
        case "ArrowUp":
        case "ArrowLeft":
          event.preventDefault();
          switchSlideItem("prev");
          break;

        case "ArrowRight":
        case "ArrowDown":
          event.preventDefault();
          switchSlideItem("next");
          break;

        default:
        // no default
      }
    },
    [switchSlideItem],
  );

  useEffect(() => {
    if (initialSlideIndex > 0) {
      scrollOnClick(initialSlideIndex, false);
    }
  }, [initialSlideIndex, scrollOnClick]);

  return {
    activeIndexes,
    carouselRef,
    scrollOnClick,
    slideIndex,
    slideKeyDown,
    slideRefs,
    switchSlideItem,
  };
};
