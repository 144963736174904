"use client";
import {
  type AdLoadingMode,
  buildResponsiveAdProps,
  ResponsiveAd,
} from "@yahoo-creators/monetization";
import { SCREEN_WIDTH } from "@yahoo-news/util";
import classNames from "classnames";
import { memo, useState, type FC } from "react";
import { getBenjiContainerId } from "@/configs/benji";
import { useRequestContext } from "@/lib/request/client";

type Props = {
  backgroundColor?: string;
  loading?: AdLoadingMode;
  locationNumber: number;
};

const configs = buildResponsiveAdProps([
  {
    customSizeConfig: {
      Horizon: true,
    },
    device: "dt",
    screenWidth: { from: SCREEN_WIDTH.md, to: SCREEN_WIDTH.lg },
    sizes: [
      { height: 90, width: 728 },
      { height: 1, isPremium: true, width: 3 },
    ],
  },
  {
    customSizeConfig: {
      Horizon: true,
    },
    device: "dt",
    screenWidth: { from: SCREEN_WIDTH.lg },
    sizes: [
      { height: 90, width: 728 },
      { height: 250, width: 970 },
      { height: 1, isPremium: true, width: 3 },
    ],
  },
]);

// this component is memoized to prevent unnecessary rerenders of the underlying ad
export const MidCenterAd: FC<Props> = memo(
  ({ backgroundColor = "bg-grey-hair", loading, locationNumber = 1 }) => {
    const { site } = useRequestContext();
    const [premium, setPremium] = useState(false);
    const location = "mid_center";
    const placement = "main";
    const id = getBenjiContainerId(location, site, locationNumber);

    return (
      <section
        className={classNames(
          `hidden ${backgroundColor} md:flex md:min-h-[250px]`,
          !premium && "flex items-center justify-center",
        )}
        id={`mid-center-ad-${locationNumber}`}
      >
        <div className="flex items-center justify-center">
          <ResponsiveAd
            className="items-center justify-center"
            configs={configs}
            id={id}
            loading={loading}
            location={location}
            locationNumber={locationNumber}
            onResize={(_, isPremium) => setPremium(isPremium)}
            pageRegion="index"
            placement={placement}
            resizable
            site={site}
          />
        </div>
      </section>
    );
  },
);
MidCenterAd.displayName = "MidCenterAd";
