import { type I13NSec, useRapid } from "@yahoo-creators/i13n";
import classNames from "classnames";
import { PrimaryHeading } from "../PrimaryHeading";
type SectionProps = {
  children: any;
  className?: string;
  heading?: string;
  i13nSec: I13NSec;
  subtitle?: string;
  url?: string;
  variant?: string;
};

export const Section = ({
  children,
  className,
  heading,
  i13nSec,
  subtitle,
  url,
  variant,
}: SectionProps) => {
  useRapid(i13nSec);

  return (
    <section
      className={classNames(
        className,
        "m-auto my-6 w-full max-w-[1504px] last:mb-0 md:my-8 lg:px-8 last:lg:pb-8",
        !variant && "px-4 md:px-6",
      )}
      id={i13nSec}
    >
      {heading && (
        <header>
          <PrimaryHeading
            title={heading}
            url={url}
            subtitle={subtitle}
            variant={variant}
          />
        </header>
      )}
      {children}
    </section>
  );
};
