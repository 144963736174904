"use client";

import {
  type AdLoadingMode,
  buildResponsiveAdProps,
  ResponsiveAd,
  type ScreenSpecificAdProps,
} from "@yahoo-creators/monetization";
import { SCREEN_WIDTH } from "@yahoo-news/util";
import { memo, type FC } from "react";
import { useRequestContext } from "@/lib/request/client";

const configsMON = buildResponsiveAdProps([
  {
    device: "dt",
    screenWidth: { from: SCREEN_WIDTH.lg },
    sizes: [
      { height: 250, width: 300 },
      { height: 600, width: 300 },
    ],
  },
]);

const configsLRec = buildResponsiveAdProps([
  {
    device: "dt",
    screenWidth: { from: SCREEN_WIDTH.lg },
    sizes: [{ height: 250, width: 300 }],
  },
]);

interface Props {
  loading?: AdLoadingMode;
}
// this component is memoized to prevent unnecessary rerenders of the underlying ad
export const RightRailAdStack: FC<Props> = memo(({ loading }) => {
  const { site } = useRequestContext();
  interface adStackingConfig {
    config: ScreenSpecificAdProps[];
    location: string;
  }

  const adStackingData: Record<string, adStackingConfig> = {
    "sda-LREC3-iframe": { config: configsLRec, location: "mid_right_a" },
    "sda-LREC4-iframe": { config: configsLRec, location: "mid_right_b" },
    "sda-MON2-iframe": { config: configsMON, location: "mid_right_c" },
  };

  const placement = "main";

  return (
    <>
      {Object.keys(adStackingData).map((id: string) => (
        <ResponsiveAd
          className="mb-5"
          configs={adStackingData[id].config}
          loading={loading}
          location={adStackingData[id].location}
          placement={placement}
          pageRegion="index"
          resizable
          site={site}
          stackGroup="col2Bottom"
          id={id}
          key={id}
        />
      ))}
    </>
  );
});
