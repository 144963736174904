import classNames from "classnames";
import { type ReactNode } from "react";

// 2 column layout with a right rail that is 3-cols on XL, 300px on LG
export const FixedWidthRightRailLayout = ({
  children,
  className,
}: {
  children: ReactNode;
  className?: string;
}) => {
  return (
    <div
      className={classNames(
        className,
        "grid grid-cols-2 gap-4 lg:grid-cols-[_1fr_300px] lg:gap-6 xl:grid-cols-12 xl:gap-x-4",
      )}
    >
      {children}
    </div>
  );
};
