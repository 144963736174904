"use client";

import { getDataYlkString, type I13NSec, useRapid } from "@yahoo-creators/i13n";
import { type ScreenSize } from "@yahoo-news/util";
import { useScreenSize } from "@yahoo-news/util/hooks/useDeviceSize";
import classNames from "classnames";
import { useIntl } from "react-intl";
import { Carousel } from "@/components/article/common/Carousel";
import { carouselStyles } from "@/components/article/common/CarouselNavControls";
import { type StoryItem } from "@/lib/streamDataUtils";
import { TopStoryCardLarge } from "../trendingStories/TopStoryCardLarge";

type TopTableProps = {
  trending: StoryItem[];
};

const TOP_STORIES_SEC: I13NSec = "y4c-trending-stories";
const TOP_STORIES_SUB_SEC: I13NSec = "creator-hub";

const stylesByPosition: {
  [key: number]: { container: string; text: string };
} = {
  0: { container: "!bg-batcave", text: "!text-white" },
  1: { container: "!bg-creator-yellow", text: "!text-black" },
  2: { container: "!bg-creator-purple", text: "!text-white" },
};

export const TrendingStories = ({ trending }: TopTableProps) => {
  useRapid(TOP_STORIES_SEC);
  const intl = useIntl();

  const slides = trending.map((story, index) => {
    const styles = stylesByPosition[index % 3];
    return (
      <TopStoryCardLarge
        className={classNames(
          "transition-shadow hover:shadow-[0_-2px_8px_0_rgba(0,0,0,0.25)]",
          styles.container,
        )}
        storyItem={story}
        styles={{
          authorText: classNames("text-[16px]", styles.text),
          title: styles.text,
        }}
      />
    );
  });

  const screenSize = useScreenSize({ updateOnResize: true });
  const itemsPerSlide = getItemsPerSlide(screenSize);

  return (
    <section
      id={TOP_STORIES_SEC}
      data-ylk={getDataYlkString({
        sec: TOP_STORIES_SEC,
        subsec: TOP_STORIES_SUB_SEC,
      })}
    >
      <div className="m-auto max-w-uh-custom p-4 md:p-6 lg:px-8">
        <Carousel
          carouselStyles="flex -mr-4 md:-mr-0 -ml-2 xl:-ml-3"
          displaySlideCount={screenSize !== null}
          titleStyles="!text-2xl font-bold !mb-1"
          disableNavIndicator
          itemsPerSlide={itemsPerSlide}
          slides={slides}
          // Remember to keep slide width percentages in sync with `itemsPerSlide`!
          slideStyles="w-[calc(100%_-_2rem)] sm:w-[calc(50%_-_1rem)] lg:w-[calc(33%_-_.75rem)] 2xl:w-[calc(25%_-_.5rem)] shrink-0 last:mr-8 sm:last:mr-4 md:last:mr-0 relative p-2 xl:px-3"
          navStyles={{
            ...carouselStyles.pillStyles,
            button:
              "flex items-center p-2 justify-center rounded-full border border-[#ebe5ff] size-10 transition-colors",
            container:
              "absolute right-0 top-0 z-[1] flex gap-2.5 hidden sm:flex md:top-[-5px]",
            disabled:
              "bg-white border border-pebble text-gandalf cursor-not-allowed",
            enabled: "bg-[#a24df8] text-white hover:bg-brand-purple",
          }}
          title={intl.formatMessage({ id: "creators.TRENDING_STORIES_TITLE" })}
        />
      </div>
    </section>
  );
};

function getItemsPerSlide(screenSize: ScreenSize | null) {
  switch (screenSize) {
    case "xs":
      return 1;
    case "sm":
    case "md":
      return 2;
    case "lg":
    case "xl":
      return 3;
    case "2xl":
      return 4;
    default:
      return 1;
  }
}
