import { I13nButton } from "@yahoo-creators/i13n";
import classNames from "classnames";
import { useIntl } from "react-intl";

export type CarouselNavIndicatorStyles = {
  /**
   * Class names for active indicator dot
   */
  active?: string;
  /**
   * Class names for indicator dot whether active or not
   */
  always?: string;
  /**
   * Class names for button wrapper
   */
  button?: string;
  /**
   * Class names for inactive indicator dot
   */
  inactive?: string;
  /**
   * Class names for wrapper div
   */
  container?: string;
};

type Props = {
  slideIndex: number;
  totalSlidesXs: number;
  styles?: CarouselNavIndicatorStyles;
  onIndexClick: (index: number) => void;
};

export const CarouselNavIndicators = ({
  slideIndex,
  totalSlidesXs,
  styles,
  onIndexClick,
}: Props) => {
  const { formatMessage } = useIntl();
  return (
    <div
      role="group"
      aria-label={formatMessage({ id: "article.SLIDE_CONTROLS" })}
      className={classNames(styles?.container || "flex justify-center gap-2")}
    >
      {Array.from({ length: totalSlidesXs }).map((_, index) => {
        const isActive = slideIndex === index;
        return (
          <I13nButton
            key={index}
            aria-disabled={isActive}
            aria-label={formatMessage(
              { id: "article.CAROUSEL_SLIDE_BUTTON" },
              { index: index + 1, total: totalSlidesXs },
            )}
            className={styles?.button}
            onClick={() => onIndexClick(index)}
            dataYlk={{
              cpos: 1,
              elm: "slide-dot",
              itc: 1,
              mpos: 1,
              pos: index + 1,
              slk: index + 1,
            }}
          >
            <div
              className={classNames(styles?.always || "size-2 rounded-full", {
                [styles?.active || "bg-batcave"]: isActive,
                [styles?.inactive || "bg-pebble"]: !isActive,
              })}
            />
          </I13nButton>
        );
      })}
    </div>
  );
};
