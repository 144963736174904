import classNames from "classnames";
import { type FC } from "react";

type HeadingProps = {
  className?: string;
  title: string;
};

export const Heading: FC<HeadingProps> = ({ className, title }) => {
  return (
    <h3>
      <span
        className={classNames(
          "stretched-box headline-l font-bold group-hover/follow:underline",
          className,
        )}
      >
        {title}
      </span>
    </h3>
  );
};
