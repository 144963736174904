"use client";

import { useEsiVars } from "@yahoo-creators/edge";
import { HomepageFooter } from "@yahoo-creators/footer";
import { type FC } from "react";
import { type EdgeSideIncludes } from "@/configs/esi";
import { useConfigContext } from "@/contexts/ConfigContext";
import { useRequestContext } from "@/lib/request/client";

interface Props {
  className?: string;
}

export const SiteFooter: FC<Props> = ({ className }) => {
  const { lang, partner, site } = useRequestContext();
  const { footer: footerConfig } = useConfigContext();
  const { acookieFields } = useEsiVars<Pick<EdgeSideIncludes, "acookieFields">>(
    {
      acookieFields: "",
    },
  );

  return (
    <HomepageFooter
      acookieFields={acookieFields}
      className={className}
      lang={lang}
      partner={partner}
      site={site}
      links={footerConfig.links}
    />
  );
};
