"use client";

import {
  type AdLoadingMode,
  buildResponsiveAdProps,
  ResponsiveAd,
  type AdWrapperProps,
} from "@yahoo-creators/monetization";
import { SCREEN_WIDTH } from "@yahoo-news/util";
import classNames from "classnames";
import { memo, type FC } from "react";
import { useIntl } from "react-intl";
import { getBenjiContainerId } from "@/configs/benji";
import { useRequestContext } from "@/lib/request/client";

type Props = {
  loading?: AdLoadingMode;
  locationNumber: number;
};

const AdWrapper: FC<AdWrapperProps> = ({
  children,
  displayMode,
  isPremium,
}) => {
  const intl = useIntl();
  return (
    <div
      className={classNames(
        "bg-marshmallow md:hidden dark:bg-ramones",
        displayMode === "collapsed" && "hidden",
      )}
    >
      <div
        className={classNames(
          "py-2 text-center text-xs uppercase",
          !isPremium && "hidden",
        )}
      >
        {intl.formatMessage({ id: "monetization.AD_PLACEHOLDER" })}
      </div>
      <div
        className={classNames(
          "flex w-full flex-nowrap",
          !isPremium && "justify-center",
        )}
      >
        {children}
      </div>
    </div>
  );
};

const configs = buildResponsiveAdProps([
  {
    customSizeConfig: {
      Lighthouse: true,
      Spotlight: true,
    },
    device: "mw",
    screenWidth: { to: SCREEN_WIDTH.md },
    sizes: [
      { height: 250, width: 300 },
      { height: 2, isPremium: true, width: 3 },
      { height: 2, isPremium: true, width: 2 },
    ],
    wrapper: AdWrapper,
  },
]);

// this component is memoized to prevent unnecessary rerenders of the underlying ad
export const MidCenterAdMweb: FC<Props> = memo(
  ({ loading, locationNumber }) => {
    const { site } = useRequestContext();
    const location = "mid_center";
    const placement = "main";
    const id = getBenjiContainerId(location, site, locationNumber);

    return (
      <section className="bg-grey-hair" id={`mid-center-ad-${locationNumber}`}>
        <ResponsiveAd
          configs={configs}
          id={id}
          loading={loading}
          location={location}
          locationNumber={locationNumber}
          pageRegion="index"
          placement={placement}
          resizable
          site={site}
        />
      </section>
    );
  },
);
MidCenterAdMweb.displayName = "MidCenterAdMweb";
