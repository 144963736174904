"use client";
import { useRapid, type I13NSec } from "@yahoo-creators/i13n";
import { times } from "@yahoo-news/util";
import classNames from "classnames";
import { type ReactNode, useCallback, useMemo } from "react";
import { useIntl } from "react-intl";
import ErrorBoundary from "@/components/common/ErrorBoundary";
import { StickyRightRailAd } from "@/components/shared/ads/StickyRightRailAd";
import { defaultStreamViewParams } from "@/components/shared/Stream/config";
import { STREAM_LOADING_STATES } from "@/contexts/NcpCacheContext/types";
import { type ShoppingConfigItemsType } from "@/lib/astronomer/types";
import {
  FETCH_CREDENTIALS,
  fetchStream,
  type StreamArguments,
  useStream,
  type NCPStream,
  type NCPStreamViewParams,
} from "@/lib/ncp";
import { useRequestContext } from "@/lib/request/client";
import { type StoryItem } from "@/lib/streamDataUtils";
import { RightRailAdStack } from "../../ads/RightRailAdStack";
import { type StreamItem, isStreamItem } from "../data";
import { StoryStackWithPagination } from "../StoryStackWithPagination";
import { FixedWidthRightRailLayout } from "./FixedWidthRightRailLayout";
import { Section } from "./Section";

const RIGHT_RAIL_AD_COUNT = 9;

type StreamWithRightRailAdProps = {
  adFrequency?: number;
  children?: ReactNode;
  className?: string;
  enableAdStacking?: boolean;
  firstAdIndex?: number;
  i13nSec: I13NSec;
  i13nSubsec?: string;
  initialStreamData?: NCPStream<StoryItem>;
  shoppingConfigItems?: ShoppingConfigItemsType[] | null;
  streamDescriptionId?: string;
  streamKey: string;
  streamTitleId?: string;
  streamViewParams?: NCPStreamViewParams;
  taboolaPosition?: {
    mode?: string;
    placement?: string;
    region?: string;
  };
};

export const StreamWithRightRailAd = ({
  adFrequency,
  children,
  className,
  enableAdStacking,
  firstAdIndex,
  i13nSec,
  i13nSubsec,
  initialStreamData,
  shoppingConfigItems,
  streamDescriptionId,
  streamKey,
  streamTitleId,
  streamViewParams,
  taboolaPosition,
}: StreamWithRightRailAdProps) => {
  const intl = useIntl();

  const { searchParams } = useRequestContext();

  const bottomStreamHeading =
    streamTitleId &&
    intl.formatMessage({
      id: streamTitleId,
    });

  const bottomStreamDescription =
    streamDescriptionId &&
    intl.formatMessage({
      id: streamDescriptionId,
    });

  useRapid(i13nSec);

  const streamParams = useMemo(
    (): StreamArguments<StreamItem> => ({
      credentials: FETCH_CREDENTIALS.INCLUDE,
      fetchImpl: fetchStream,
      isItemType: isStreamItem,
      params: {
        ...defaultStreamViewParams,
        ...streamViewParams,
        ...(searchParams.uuid ? { _testUuids: searchParams.uuid } : {}), // force uuid into the stream IFF query param present
      },
      streamKey,
    }),
    [streamKey, streamViewParams, searchParams],
  );

  const { fetchNextPage, items, isLoading, loadingState } = useStream({
    ...streamParams,
    initialStreamData,
    nextPaginationCursor: initialStreamData?.pagination.uuids,
  });
  const endOfStream = loadingState === STREAM_LOADING_STATES.FINISHED;

  const rightRailAdCount = useMemo(() => {
    const itemsCount = items.length
      ? items.length
      : initialStreamData?.stream.length || 0;

    // we add 1 to available slots to ensure we render an ad
    // below the viewport as the user scrolls. Prevents ads
    // from appearing in the right rail after the user has
    // reached an ad slot.
    const visibleAdSlotCount = Math.floor(itemsCount / 4) + 1;

    return visibleAdSlotCount < RIGHT_RAIL_AD_COUNT
      ? visibleAdSlotCount
      : RIGHT_RAIL_AD_COUNT;
  }, [items, initialStreamData?.stream]);

  const handleFetchStories = useCallback(() => {
    fetchNextPage();
  }, [fetchNextPage]);

  return (
    <Section
      className={classNames("grid", className)}
      heading={bottomStreamHeading}
      i13nSec={i13nSec}
      subtitle={bottomStreamDescription}
    >
      <FixedWidthRightRailLayout>
        <ErrorBoundary id="StoryStack">
          <StoryStackWithPagination
            adFrequency={adFrequency}
            firstAdIndex={firstAdIndex}
            i13nSec={i13nSec}
            i13nSubsec={i13nSubsec}
            imageFormat={streamViewParams?.imageFormat}
            items={items.length ? items : initialStreamData?.stream || []}
            onPlaceholdersVisible={handleFetchStories}
            showPlaceholders={!endOfStream && !isLoading}
            shoppingConfigItems={shoppingConfigItems}
            taboolaPosition={taboolaPosition}
          />
        </ErrorBoundary>
        <div className="relative hidden lg:block xl:col-start-10 xl:col-end-13">
          <div className="flex size-full flex-col flex-nowrap">
            {enableAdStacking ? (
              <div className="sticky top-[100px] min-h-[690px] pb-5">
                <RightRailAdStack loading="eager" />
                {children}
              </div>
            ) : (
              times(rightRailAdCount, (index) => {
                const reachedAdCount = index + 1 >= RIGHT_RAIL_AD_COUNT;

                return (
                  <div
                    key={index}
                    className={classNames(
                      "relative pb-5",
                      reachedAdCount ? "flex-auto" : "h-[900px] flex-none",
                    )}
                  >
                    <StickyRightRailAd
                      adCount={rightRailAdCount}
                      loading="lazy"
                      location="btm_right"
                      locationNumber={index}
                    />
                    {reachedAdCount && children}
                  </div>
                );
              })
            )}
          </div>
        </div>
      </FixedWidthRightRailLayout>
    </Section>
  );
};
