import { Icon } from "@yahoo/uds";
import { ChevronRight } from "@yahoo/uds-icons";
import { I13nAnchor } from "@yahoo-creators/i13n";
import classNames from "classnames";

interface PrimaryHeadingProps {
  subtitle?: string;
  title: string;
  url?: string;
  variant?: string;
}

export const PrimaryHeading = ({
  subtitle,
  title,
  url,
  variant,
}: PrimaryHeadingProps) => {
  return (
    <div
      className={classNames("mb-4", variant === "shopping" && "ml-5 lg:ml-0")}
    >
      <h2 className="display-m inline-block font-centra text-inkwell">
        {url ? (
          <I13nAnchor
            className="display-m flex items-center gap-1.5 text-inkwell hover:underline"
            href={url}
            dataYlk={{
              elm: "navcat",
              itc: 0,
            }}
          >
            {title}
            <Icon
              name={ChevronRight}
              size="md"
              className="mt-1 text-brand-purple"
            />
          </I13nAnchor>
        ) : (
          title
        )}
      </h2>
      {subtitle && (
        <p className="text-sm leading-snug text-battleship">{subtitle}</p>
      )}
    </div>
  );
};
