import classNames from "classnames";
import { useContext } from "react";
import { useIntl } from "react-intl";
import { ArticleLink } from "@/components/common/ArticleLink";
import { NCPImage } from "@/components/shared/NCPImage/index";
import { type StreamImage } from "@/components/shared/Stream/data";
import { COMMON_NTK_LINK_I13N_PARAMS } from "@/lib/i13n";
import { I13nStartIndexContext } from "@/lib/I13nStartIndexContext";
import { type StoryItem } from "@/lib/streamDataUtils";
import { Heading } from "./TopStoryHeading";

export const TopStoryCardLarge = ({
  storyItem,
  styles,
  className,
}: {
  storyItem: StoryItem;
  styles?: {
    authorText?: string;
    authorImage?: string;
    headerContainer?: string;
    title?: string;
  };
  className?: string;
}) => {
  const {
    attribution,
    bypassModal,
    id,
    instrumentation,
    thumbnail,
    title,
    url,
  } = storyItem;
  const i13nStartIndex = useContext(I13nStartIndexContext);

  const i13nIndex = i13nStartIndex + 1;

  return (
    <ArticleLink
      className=""
      href={url}
      dataYlk={{
        cpos: 2,
        g: id,
        mab_test: instrumentation?.mab,
        mpos: 2,
        pos: i13nIndex,
        ss_cid: id,
        ...COMMON_NTK_LINK_I13N_PARAMS,
      }}
      disableModal={bypassModal}
    >
      <article
        className={classNames(
          "group/follow relative flex h-full flex-col rounded-lg bg-white",
          className,
        )}
      >
        <div
          className={classNames(
            "flex min-w-0 flex-1 flex-col justify-between gap-6 p-3",
            styles?.headerContainer,
          )}
        >
          <Heading title={title} className={styles?.title} />
          <Attribution
            image={attribution.image}
            source={attribution.source}
            classNameImage={styles?.authorImage}
            classNameSource={styles?.authorText}
          />
        </div>
        <NCPImage
          alt=""
          className="w-full rounded-b-lg"
          image={thumbnail}
          size="228x128|2|95"
          priority
        />
      </article>
    </ArticleLink>
  );
};

function Attribution({
  image,
  source,
  classNameImage,
  classNameSource,
}: {
  image?: StreamImage | null;
  source: string;
  classNameImage?: string;
  classNameSource?: string;
}) {
  const intl = useIntl();

  return (
    <div className="flex flex-row items-center truncate font-bold">
      <NCPImage
        alt={intl.formatMessage(
          { id: "creators.PROFILE_IMAGE" },
          { brandName: source },
        )}
        className={classNames("mr-2 size-10 rounded-full", classNameImage)}
        image={image}
        size="80x80"
        priority
      />
      <span
        className={classNames(
          "line-clamp-2 text-ellipsis text-wrap",
          classNameSource,
        )}
      >
        {source}
      </span>
    </div>
  );
}
